<template>
	<section class="background-div" v-bind:class="!isMobile ? 'justify-center' : 'justify-top'">
		<div class="card-container" v-bind:class="!isMobile ? 'card-container-desktop' : ''">
			<div>
				<div>
					<div class="card card-info card-dark">
						<div class="card-header text-center">
							<h4 class="m-0">Fazer login</h4>
						</div>
						<div class="card-body">
							<b-form @submit.prevent="login">
								<div>
									<b-form-input
										id="user"
										v-model="usuario"
										class="form-control input-sm chat-input"
										placeholder="Usuário"
									/>
								</div>
								<br>
								<div>
									<b-form-input
										id="pass"
										v-model="senha"
										class="form-control input-sm chat-input"
										type="password"
										placeholder="Senha"
									/>
								</div>
								<div class="text-center">
									<button
										type="submit"
										class="btn-primary w-100 mt-4"
										:disabled="!usuario || !senha"
									>
										Fazer login
									</button>
								</div>
								<div
									v-if="goLogin"
									style="display: flex; align-items: center; justify-content: center; margin-top: -2.2rem; margin-bottom: 1.2rem; color: blue"
								>
									<div class="spinner-border" role="status"/>
								</div>
								<div class="text-center mt-3">
									<span @click="forget()" style="cursor: pointer; color: white">Esqueceu a sua senha?</span>
								</div>
							</b-form>
						</div>
						<div class="text-center mt-2" style="border-top: 1px solid rgba(0, 0, 0, 0.125); width: 100%; position: relative; top: -12px; padding: 12px 0 6px;">
							<span @click="createUser()" style="cursor: pointer; color: white">Criar uma conta</span>
						</div>
					</div>
					<div class="login-msg" v-if="success || error">
						<span v-if="success" class="success-msg">
							Logado com sucesso! Redirecionando...
						</span>
						<span v-else-if="error" class="error-msg">
							{{ error }}
						</span>
					</div>
					<div v-else div style="height: 2rem;"/>
				</div>
			</div>
		</div>
	</section>
</template>

<style scoped>
	.login-msg {
		text-align: center;
		padding-top: 0.5rem;
	}

	.error-msg {
		color: red;
	}

	.success-msg {
		color: #008000;
	}
</style>

<script>
	import { AuthService } from "@/services/auth";
	import { mapState } from "vuex";

	export default {
		data () {
			return {
				usuario: "",
				senha: "",
				error: "",
				success: false,
				authService: new AuthService,
				backgroundImageUrl: "../assets/img/background.png",
				isMobile: false,
				goLogin: false
			};
		},

		computed: mapState(["usuarioLogado"]),

		beforeDestroy () {
			window.removeEventListener("resize", this.checkIfMobile);
		},

		mounted () {
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);
			if (this.usuarioLogado) this.$router.push({ path: "/"});
		},

		methods: {
			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 600;
			},

			forget () {
				this.$router.push({ path: "/forget"});
			},

			createUser () {
				this.$router.push({ path: "/createUser"});
			},

			async login () {
				try {
					if (!this.usuario || !this.senha) return;
					this.goLogin = true;
					this.error = "";
					this.success = false;
					this.$store.commit("setUsuarioLogado", null);

					const response = await this.authService.login({ user: this.usuario, senha: this.senha });
					this.usuario = "";
					this.senha = null;
					if (!response.success) {
						throw new Error(response.error);
					} else {
						this.$store.commit("setUsuarioLogado", response.usuarioLogado);
						this.success = true;
						this.$router.replace(this.$route.params.next || "/");
					}
				} catch (error) {
					this.goLogin = false;
					console.log(error);
					this.error = error.message || "Erro desconhecido, por favor tente novamente.";
				}
			}
		}
	};
</script>
